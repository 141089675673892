const reducer = (state, action) => {
    switch (action.type) {
        case 'updateAnnualSalary':
            return { ...state.updateAnnualSalary(action.value) };

        case 'updateNumberOfTeacher':
            if (isNaN(action.value) || action.value <= 0) {
                action.value = 0;
            }
            return { ...state, numberOfTeacher: parseInt(action.value) };
            
        case 'updateActivity':
            if (isNaN(action.value) || action.value <= 0) {
                action.value = 0;
            }
            const newActivity = state.activityMatrix.map(item => {
                if (item.title === action.title) {
                    return { ...item, [action.key]: parseFloat(action.value) };
                }
                return { ...item };
            });
            return { ...state, activityMatrix: newActivity };
        default:
            return state;
    }
}

export default reducer;