import ActivityMatrix from './Constant/ActivityMatrix';

const Store = function () {
    this.annualSalary = 35000;
    this.updateAnnualSalary = function (value) {
        if (isNaN(value) || value <= 0) {
            value = 0;
        }
        this.annualSalary = parseFloat(value);
        return this;
    };
    this.hoursPerDay = 8;
    this.annualWorkingDays = 195;
    this.annualWorkingWeeks = 39;
    this.numberOfTeacher = 4;
    this.annualWorkingHours = function () {
        const value = this.hoursPerDay * this.annualWorkingDays;
        if (isNaN(value)) {
            return 0;
        }
        return value;
    };
    this.payPerHour = function () {
        const value = this.annualSalary / this.annualWorkingHours();
        if (isNaN(value)) {
            return '0.00';
        }
        return value.toFixed(2);
    };
    this.payPerMinute = function () {
        const value = this.payPerHour() / 60;
        if (isNaN(value)) {
            return '0.00';
        }
        return value.toFixed(2);
    };
    this.setAnnualSalary = function (value) {
        this.annualSalary = value;
    };

    const parent = this;

    this.activityMatrix = ActivityMatrix().map(item => {
        return {
            ...item,
            timeSavedUsingAllMFL: function () {
                const value = this.timeToMakeMySelf - this.timeToMakeWithAllIn;
                if (isNaN(value)) {
                    return 0;
                }
                return value;
            },
            moneySavedUsingAllMFL: function () {
                const value = this.timeSavedUsingAllMFL() * parent.payPerMinute();
                if (isNaN(value)) {
                    return 0;
                }
                return value;
            },
            numberOfTimesInWeek: 5,
            minutesSavedInWeek: function () {
                const value = this.timeSavedUsingAllMFL() * this.numberOfTimesInWeek;
                if (isNaN(value)) {
                    return 0;
                }
                return value;
            },
            moneySavedInWeek: function () {
                const value = this.moneySavedUsingAllMFL() * this.numberOfTimesInWeek;
                if (isNaN(value)) {
                    return 0;
                }
                return value;
            },
            hoursSavedInYear: function () {
                const value = this.minutesSavedInWeek() * parent.annualWorkingWeeks / 60;
                if (isNaN(value)) {
                    return 0;
                }
                return value;
            },
            moneySavedInYear: function () {
                const value = this.moneySavedInWeek() * parent.annualWorkingWeeks;
                if (isNaN(value)) {
                    return 0;
                }
                return value;
            }
        }
    });

    this.setActivityMatrix = function (value) {
        this.activityMatrix = value;
    }
};

export default Store;