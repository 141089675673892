import { useContext } from "react";
import { NumericFormat } from "react-number-format";
import DataContext from "../Context/Data";


const Highlights = () => {
    const state = useContext(DataContext);

    const updateAnnualSalary = (event) => {
        let value = parseFloat(event.target.value);
        if (value <= 0) {
            value = 0;
        }
        state.dispatch({ type: 'updateAnnualSalary', value });
    }

    return (
        <section className="banner-wrapper">
            <div className="container">
                <div className="banner-top-btn">
                    <button className="orange-button">
                        <NumericFormat value={state.annualSalary} displayType={'text'} thousandSeparator={true} prefix={'£'} />
                    </button>
                </div>
                <div className="row banner-row-wrapper">
                    <div className="col-lg-4">
                        <div className="banner-left-col">
                            <div className="banner-left-common">
                                <p>Total teaching days</p>
                                <p>per year</p>
                                <p><strong>{state.annualWorkingDays}</strong></p>
                                <img src="/images/calender-icon.png" alt="icon" className="calender-icon" />
                                <img src="/images/line-2.png" alt="line" className="orange-line" />
                            </div>
                            <div className="banner-left-common">
                                <p>Total working weeks</p>
                                <p>per year</p>
                                <p><strong>{state.annualWorkingWeeks}</strong></p>
                                <img src="/images/blue-calcender.png" alt="icon" className="calender-icon" />
                                <img src="/images/skyblue-line.png" alt="line" className="orange-line skyblue-line" />
                            </div>
                            <div className="banner-left-common banner-left-common-custom">
                                <p>Average hours you</p>
                                <p>work per day</p>
                                <p><strong>{state.hoursPerDay}</strong></p>
                                <img src="/images/bluedark.png" alt="icon" className="calender-icon" />
                                <img src="/images/line-1.png" alt="line" className="orange-line blue-line" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="banner-center-col">
                            <div className="banner-center-content-wraper">
                                <img src="/images//user.png" alt="user" className="user-icon" />
                                <h5>My annual salary</h5>
                                <div className="editor-wrapper">
                                    <input type="text" defaultValue={state.annualSalary} onChange={updateAnnualSalary} />
                                    <button><img src="/images/edit.png" className="edit-img" alt="" /> </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="banner-right-col">
                            <div className="banner-right-common">
                                <img src="/images/pink-calender.png" alt="icon" className="right-calender-icon" />
                                <img src="/images/pink-line.png" alt="line" className="orange-line pink-line" />
                                <div className="content-wrapper-right">
                                    <p>Total working hours</p>
                                    <p>per year</p>
                                    <p><strong>{state.annualWorkingHours()}</strong></p>
                                </div>
                            </div>
                            <div className="banner-right-common">
                                <img src="/images/calender-icon.png" alt="icon" className="right-calender-icon" />
                                <img src="/images/red-line.png" alt="line" className="orange-line pink-line" />
                                <div className="content-wrapper-right">
                                    <p>Your hourly pay</p>
                                    <p>&nbsp;</p>
                                    <p>
                                        <strong>
                                            <NumericFormat value={state.payPerHour()} displayType={'text'} thousandSeparator={true} prefix={'£'} />
                                        </strong>
                                    </p>
                                </div>
                            </div>
                            <div className="banner-right-common banner-right-common-custom">
                                <img src="/images/line-3.png" alt="line" className="orange-line green-line" />
                                <img src="/images/green-calcender.png" alt="icon" className="right-calender-icon" />
                                <div className="content-wrapper-right">
                                    <p>Your pay per minute</p>
                                    <p>&nbsp;</p>
                                    <p>
                                        <strong>
                                            <NumericFormat value={state.payPerMinute()} displayType={'text'} thousandSeparator={true} prefix={'£'} />
                                        </strong>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Highlights;