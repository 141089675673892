import Step1 from './Components/Step1';
import Step2 from './Components/Step2';
import Step3 from './Components/Step3';
import Step4 from './Components/Step4';

function App() {
    return (
        <>
            <section className="header-wrapper">
                <div className="container-fluid">
                    <div className="logo-wrapper">
                        <img src="/images/Logo.png" alt="logo" className="logo-img" />
                    </div>
                </div>
            </section>
            <Step1 />
            <Step2 />
            <Step3 />
            <Step4 />
        </>
    );
}

export default App;
