import { useContext } from 'react';
import { NumericFormat } from 'react-number-format';
import DataContext from "../Context/Data";

const Step2Steps = () => {
    const state = useContext(DataContext);

    const updateActivity = (value, key, title) => {
        if (isNaN(value) || value <= 0) {
            value = 0;
        }
        state.dispatch({ type: 'updateActivity', value, key, title });
    }

    return (
        <section className="activity-2-wrapper activity-2-wrapper22">
            <div className="container">
                <div className="banner-top-btn">
                    <button className="orange-buttonn">Activity</button>
                </div>
                <h5 className="mb-5 text-center">Step 2 Instructions: in the table below, enter in minutes how long it takes you to create the following activities.</h5>
                <div className="d-flex align-items-start">
                    <div className="activity-list">
                        <div className="common-1">
                            <div className="activity-name-container">
                                <button className='btn btn-link active activity-name'>Activity Name</button>
                            </div>
                            <div className="light-blue common-div common-green-bg">
                                How long does it take you to create this activity by hand
                            </div>
                            <div className="light-blue common-div common-green-bg bg-pink">
                                Time To Create Using <br/>All-In MFL
                            </div>
                            <div className="light-blue common-div common-green-bg bg-orange">
                                Time Saved Using <br/>All-In MFL (Mins)
                            </div>
                            <div className="light-blue common-div common-green-bg bg-blue">
                                £ € $ Saving VS My Hourly Wago
                            </div>
                        </div>
                        {state.activityMatrix.map((item, key) => {
                            return (
                                <div className="common-1" key={item.title}>
                                    <div className="activity-name-container">
                                        <button className='btn btn-link activity-name'>{item.title}</button>
                                    </div>
                                    <input type="text" onChange={(event) => updateActivity(event.target.value, 'timeToMakeMySelf', item.title)} defaultValue={item.timeToMakeMySelf} className="dark-green common-div" />
                                    <input type="text" onChange={(event) => updateActivity(event.target.value, 'timeToMakeWithAllIn', item.title)} defaultValue={item.timeToMakeWithAllIn} className="dark-pink common-div" />
                                    <div className="dark-orange common-div">
                                        <NumericFormat value={(item.timeToMakeMySelf - item.timeToMakeWithAllIn).toFixed(2)} displayType={'text'} thousandSeparator={true} />
                                    </div>
                                    <div className="light-blue common-div">
                                        <NumericFormat value={((item.timeToMakeMySelf - item.timeToMakeWithAllIn).toFixed(2) * state.payPerMinute()).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'£'}/>
                                    </div>
                                </div>
                            )
                        })}
                        <div className="common-1 bg-light">
                            <div className="activity-name-container">
                                <button className='btn btn-link activity-name'>Total</button>
                            </div>
                            <div className="dark-green common-div">
                                <NumericFormat value={state.activityMatrix.reduce((a, b) => a + b.timeToMakeMySelf, 0).toFixed(2)} displayType={'text'} thousandSeparator={true} />
                            </div>
                            <div className="dark-pink common-div">
                                <NumericFormat value={state.activityMatrix.reduce((a, b) => a + b.timeToMakeWithAllIn, 0).toFixed(2)} displayType={'text'} thousandSeparator={true} />
                            </div>
                            <div className="dark-orange common-div">
                                <NumericFormat value={(state.activityMatrix.reduce((a, b) => a + b.timeSavedUsingAllMFL(), 0)).toFixed(2)} displayType={'text'} thousandSeparator={true} />
                            </div>
                            <div className="light-blue common-div">
                                <NumericFormat value={((state.activityMatrix.reduce((a, b) => a + b.moneySavedUsingAllMFL(), 0)).toFixed(2) * state.payPerMinute()).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'£'}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Step2Steps;