import { useContext } from 'react';
import { NumericFormat } from 'react-number-format';
import DataContext from "../Context/Data";

const Step2Steps = () => {
    const state = useContext(DataContext);

    const updateActivity = (value, key, title) => {
        if (isNaN(value) || value <= 0) {
            value = 0;
        }
        state.dispatch({ type: 'updateActivity', value, key, title });
    }

    return (
        <section className="activity-2-wrapper activity-2-wrapper22">
            <div className="container-fluid">
                <div className="banner-top-btn">
                    <button className="orange-button">Activity</button>
                </div>
                <h5 className="mb-5 text-center">Step 3 Instructions: in the table below, enter in minutes how many of each of these activities you make per week</h5>
                <div className="d-flex align-items-start">
                    <div className="activity-list">
                        <div className="common-1">
                            <div className="activity-name-container">
                                <button className='btn btn-link active activity-name'>Activity Name</button>
                            </div>
                            <div className="light-blue common-div common-green-bg">
                                How many times do I make this per week?
                            </div>
                            <div className="light-blue common-div common-green-bg bg-pink">
                                Minutes I save using All-In
                            </div>
                            <div className="light-blue common-div common-green-bg bg-orange">
                                which is equivalent to
                            </div>
                            <div className="light-blue common-div common-green-bg bg-blue">
                                Hours I save per year ...
                            </div>
                            <div className="light-blue common-div common-green-bg bg-skyblue">
                                £ per year equivalent
                            </div>
                        </div>
                        {state.activityMatrix.map((item, index) => {
                            return (
                                <div className="common-1" key={item.title}>
                                    <div className="activity-name-container">
                                        <button className='btn btn-link activity-name'>{item.title}</button>
                                    </div>
                                    <input type="text" onChange={(event) => updateActivity(event.target.value, 'numberOfTimesInWeek', item.title)} defaultValue={item.numberOfTimesInWeek} className="dark-green common-div" />
                                    <div className="dark-pink common-div">
                                        <NumericFormat value={item.minutesSavedInWeek().toFixed(2)} displayType={'text'} thousandSeparator={true} />
                                    </div>
                                    <div className="dark-orange common-div">
                                        <NumericFormat value={item.moneySavedInWeek().toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'£'}/>
                                    </div>
                                    <div className="light-blue common-div">
                                        <NumericFormat value={item.hoursSavedInYear().toFixed(2)} displayType={'text'} thousandSeparator={true} />
                                    </div>
                                    <div className="skyblue common-div">
                                        <NumericFormat value={item.moneySavedInYear().toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'£'}/>
                                    </div>
                                </div>
                            )
                        })}
                        <div className="common-1 bg-light">
                            <div className="activity-name-container">
                                <button className='btn btn-link activity-name'>Total</button>
                            </div>
                            <div className="dark-green common-div">
                                <NumericFormat value={state.activityMatrix.reduce((a, b) => a + b.numberOfTimesInWeek, 0)} displayType={'text'} thousandSeparator={true} />
                            </div>
                            <div className="dark-pink common-div">
                                <NumericFormat value={state.activityMatrix.reduce((a, b) => a + b.minutesSavedInWeek(), 0).toFixed(2)} displayType={'text'} thousandSeparator={true} />
                            </div>
                            <div className="dark-orange common-div">
                                <NumericFormat value={state.activityMatrix.reduce((a, b) => a + b.moneySavedInWeek(), 0).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'£'}/>
                            </div>
                            <div className="light-blue common-div">
                                <NumericFormat value={state.activityMatrix.reduce((a, b) => a + b.hoursSavedInYear(), 0).toFixed(2)} displayType={'text'} thousandSeparator={true} />
                            </div>
                            <div className="skyblue common-div">
                                <NumericFormat value={state.activityMatrix.reduce((a, b) => a + b.moneySavedInYear(), 0).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'£'}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Step2Steps;