const ActivityMatrix = () => ([
    {
        title: 'Mix & Match',
        timeToMakeMySelf: 5,
        timeToMakeWithAllIn: 0.5,
        numberOfTimesInWeek: 5,
    },
    {
        title: 'Fastest Hand',
        timeToMakeMySelf: 8,
        timeToMakeWithAllIn: 0.5,
        numberOfTimesInWeek: 3,
    },
    {
        title: 'Unjumble Sentences',
        timeToMakeMySelf: 5,
        timeToMakeWithAllIn: 0.5,
        numberOfTimesInWeek: 3,
    },
    {
        title: 'Pyramid Translation',
        timeToMakeMySelf: 20,
        timeToMakeWithAllIn: 1,
        numberOfTimesInWeek: 1,
    },
    {
        title: 'Narrow Reading',
        timeToMakeMySelf: 20,
        timeToMakeWithAllIn: 5,
        numberOfTimesInWeek: 1,
    },
    {
        title: 'Gap Fill Listening',
        timeToMakeMySelf: 8,
        timeToMakeWithAllIn: 0.5,
        numberOfTimesInWeek: 5,
    },
    {
        title: 'Parallel Gapped Text',
        timeToMakeMySelf: 10,
        timeToMakeWithAllIn: 0.5,
        numberOfTimesInWeek: 1,
    },
    {
        title: 'Highlight Parts of Speech',
        timeToMakeMySelf: 5,
        timeToMakeWithAllIn: 0.5,
        numberOfTimesInWeek: 2,
    },
    {
        title: 'Make a Worksheet',
        timeToMakeMySelf: 12,
        timeToMakeWithAllIn: 0.5,
        numberOfTimesInWeek: 2,
    },
    {
        title: 'Differentiate Worksheet',
        timeToMakeMySelf: 8,
        timeToMakeWithAllIn: 1.5,
        numberOfTimesInWeek: 3,
    },
    {
        title: 'Mark vocab Test (per class)',
        timeToMakeMySelf: 10,
        timeToMakeWithAllIn: 0.5,
        numberOfTimesInWeek: 5,
    },
    {
        title: 'Write grammar feedback',
        timeToMakeMySelf: 10,
        timeToMakeWithAllIn: 0.5,
        numberOfTimesInWeek: 5,
    },
]);

export default ActivityMatrix;