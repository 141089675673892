import { useReducer, StrictMode } from "react";
import reducer from "./reducer";

import ReactDOM from 'react-dom/client';
import DataContext from './Context/Data';
import Store from './store';
import App from './App';
import reportWebVitals from './reportWebVitals';

const ReactApp = () => {
  const [state, dispatch] = useReducer(reducer, new Store());

  return (
    <StrictMode>
      <DataContext.Provider value={{
        ...state,
        dispatch
      }}>
        <App />
      </DataContext.Provider>
    </StrictMode>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<ReactApp />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
