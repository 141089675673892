import { useContext } from 'react';
import { NumericFormat } from 'react-number-format';
import DataContext from "../Context/Data";

const Step4Steps = () => {
    const state = useContext(DataContext);

    const weeklyHoursSaved = state.activityMatrix.reduce((a, b) => a + b.timeSavedUsingAllMFL(), 0) / 60;

    const avgHoursSavedWeekly = state.activityMatrix.reduce((a, b) => a + b.minutesSavedInWeek(), 0) / 60;

    const updateNumberOfTeacher = (e) => {
        state.dispatch({
            type: 'updateNumberOfTeacher',
            value: parseInt(e.target.value)
        });
    }

    return (
        <>
            <section className="activity-2-wrapper mb-4">
                <div className="container-fluid">
                    <div className="banner-top-btn">
                        <button className="orange-buttonn">Step 4</button>
                    </div>
                    <h5 className="mb-5 text-center">Time and Salary you save when using All-In MFL.</h5>
                    <div className='row'>
                        <div className="col-6">
                            <div className="footer-total-wrapper">
                                <div className="total-wrap">
                                    <div className="total-content-wrap">
                                        <p>Total Working Hours you save if you make just one each of the above activities using All-In (HOURS):</p>
                                        <div className="blue-total-wrap">
                                            <NumericFormat value={weeklyHoursSaved.toFixed(2)} displayType={'text'} thousandSeparator={true} />
                                        </div>
                                    </div>
                                    <div className="total-content-wrap">
                                        <p>How much you are paid during this time you save (£GBP):</p>
                                        <div className="blue-total-wrap">
                                            <NumericFormat value={(weeklyHoursSaved * state.payPerHour()).toFixed(2)} displayType={'text'} thousandSeparator={true} />
                                        </div>
                                    </div>
                                </div>
                                <div className="total-wrap">
                                    <div className="total-content-wrap">
                                        <p>Avg Total hours you save per week (Hours)</p>
                                        <div className="blue-total-wrap">
                                            <NumericFormat value={avgHoursSavedWeekly.toFixed(2)} displayType={'text'} thousandSeparator={true} />
                                        </div>
                                    </div>
                                </div>
                                <div className="total-wrap">
                                    <div className="total-content-wrap">
                                        <p>Hours saved per week equivalent to: </p>
                                        <div className="blue-total-wrap">
                                            <NumericFormat value={(avgHoursSavedWeekly * state.payPerHour()).toFixed(2)} displayType={'text'} thousandSeparator={true} />
                                        </div>
                                    </div>
                                </div>
                                <div className="total-wrap">
                                    <div className="total-content-wrap bg-success text-white">
                                        <p>Total salary saved per year when using All-In MFL</p>
                                        <div className="blue-total-wrap text-white" style={{background: '#3bc384'}}>
                                            <NumericFormat value={(avgHoursSavedWeekly * state.payPerHour() * state.annualWorkingWeeks).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'£'} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="footer-total-wrapper">
                                <div className="editor-wrapper">
                                    <label># Of Teacher In Your Dept</label>
                                    <input type="text" placeholder="# Of Teacher In Your Dept" onChange={updateNumberOfTeacher} defaultValue={state.numberOfTeacher} />
                                    <button><img src="/images/edit.png" className="edit-img" alt="" /> </button>
                                </div>
                                <div className="total-wrap">
                                    <div className="total-content-wrap">
                                        <p>Average Time saved per dept per week (Hours)</p>
                                        <div className="blue-total-wrap">
                                            <NumericFormat value={(avgHoursSavedWeekly * state.annualWorkingWeeks * state.numberOfTeacher).toFixed(2)} displayType={'text'} thousandSeparator={true} />
                                        </div>
                                    </div>
                                    <div className="total-content-wrap bg-success text-white">
                                        <p>Total department salary saved per year:</p>
                                        <div className="blue-total-wrap text-white" style={{background: '#3bc384'}}>
                                            <NumericFormat value={(avgHoursSavedWeekly * state.payPerHour() * state.annualWorkingWeeks * state.numberOfTeacher).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'£'} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Step4Steps;